import React from "react";
import { Parallax } from "react-parallax";



const HeroSection = () => (
    <Parallax
        bgImage="/mfroj-pic.png"
        strength={400}
        bgImageStyle={{ objectFit: "cover", width: "100%", height: "100%" }}
    >
        <div className="min-h-screen flex items-center justify-center bg-black bg-opacity-50 text-white text-center">
            <div className="space-y-6 px-4">



                <img
                    src="/mfrojsolutions.jpg"
                    alt="MFROJ Solutions Logo"
                    className="mx-auto max-w-xs md:max-w-md xl:max-w-xl mix-blend-screen rounded-xl"
                />


                <p className="text-md md:text-xl">
                    Venta, Renta y Financiamiento de productos y servicios tecnológicos para Gobierno y Empresas
                </p>

            </div>
        </div>
    </Parallax>
);




const ServicesSection = () => (
    <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-center text-3xl font-bold text-blue-700 mb-10">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {[
                { name: "Tecnología para Obra civil", icon: "🧱" },
                { name: "Cableado estructurado y eléctrico", icon: "🔌" },
                { name: "Impresión láser y servidores de datos", icon: "🖨️" },
                { name: "Mantenimiento preventivo y correctivo", icon: "🛠️" },
                { name: "Venta y/o Arrendamiento de equipo de cómputo", icon: "💻" },
                { name: "Digitalización y fotocopiado", icon: "📠" },
                { name: "Desarrollo de Inteligencia de Datos", icon: "📊" },
                { name: "Fábrica de software y apps", icon: "📱" },
                { name: "Cajeros automáticos (Saque y pague)", icon: "🏧" },
            ].map((service, i) => (
                <div key={i} className="p-5 border border-gray-200 rounded-xl shadow-md text-center">
                    <div className="text-5xl mb-4">{service.icon}</div>
                    <h3 className="text-xl font-semibold">{service.name}</h3>
                </div>
            ))}
        </div>
    </div>
);

const ClientsSection = () => (
    <div className="bg-gray-50 py-16 px-4">
        <div className="max-w-6xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-blue-700 mb-10">Clientes Principales</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[
                    "Banco del bienestar México",
                    "Secretaria de Atención Tributaria SAT",
                    "Dirección general de aduanas México",
                    "Consejo Nacional de Fomento Educativo",
                    "Consejo nacional para la cultura y las artes",
                    "Consejo nacional de ciencia y Tecnología",
                    "Instituto Federal Electoral",
                    "Instituto Latinoamericano de la Comunicación Educativa",
                    "Instituto Nacional de Antropología e Historia",
                    "Gobierno del Estado de Guanajuato",
                    "Gobierno del Estado de Jalisco",
                    "Secretaria de Finanzas de Veracruz Llave",
                    "Secretaría del Trabajo y Previsión Social",
                    "Secretaria de Finanzas del Estado de Jalisco",
                    "Secretaria de Comercio y Fomento Industrial",
                    "PEMEX Perforación",
                    "PEMEX Exploración y Producción",
                    "PEMEX Corporativo de Administración"
                ].map((client, i) => (
                    <div key={i} className="p-4 bg-white rounded-md shadow-sm">
                        <p>{client}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const BrandsSection = () => (
    <Parallax
        bgImage="/logoRIP.png"
        strength={300}
        bgImageStyle={{ backgroundColor: '#000000', backgroundBlendMode: 'multiply', objectFit: 'contain', width: '100%', height: '150%' }}
    >
        <div className="bg-black bg-opacity-40 text-white py-16 px-4">
            <div className="max-w-6xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">Nuestras Marcas</h2>
                <div className="flex flex-col items-center gap-6">
                    <img src="/ripalive-logo.jpg" alt="RIPALIVE Logo" className="w-48 h-48 object-contain rounded-md bg-[#248A3E] p-2" />
                    <h3 className="text-2xl font-semibold">RIPALIVE</h3>
                    <a
                        href="https://chat.ripalive.live/landing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white text-[#248A3E] font-semibold py-2 px-6 rounded-lg hover:bg-gray-100 transition"
                    >
                        Descargar
                    </a>
                </div>
            </div>
        </div>
    </Parallax>
);


const Footer = () => (
    <div className="bg-blue-900 text-white py-10 text-center">
        <p>© 2025 MFROJ Solutions S.A. de C.V. | Lago Meru 50, Col. Granada, Nuevo Polanco, CDMX</p>
        <p>984 118 5808</p>
    </div>
);

export default function HomePage() {
    return (
        <div className="font-sans">
            <HeroSection />
            <ServicesSection />
            <ClientsSection />
            <BrandsSection />
            <Footer />
        </div>
    );
}